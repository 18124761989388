import React from 'react';
import ContactForm from '../../components/ContactForm';
import Layout from '../../components/Layout';

export default () => (
  <Layout>
    <section className="section">
      <div className="container column is-7">
        <div className="content">
          <h1>Contact Us</h1>
          <div className="has-text-weight-semibold mb-2">
            You can find individual contact details for each trustee <a href="/about-us/our-team">here</a>, or reach us at:
          </div>
          <div className="columns">
            <div className="column">
              <p>
                <b>Address:</b><br /> <br />
                25 Park View<br />Hatch End, Pinner<br />Middlesex, HA5 4LL
              </p>
            </div>
            <div className="column">
              <p>
                <br /> <br />
                <b>Email:</b> <a href="mailto:info@beht.org">info@beht.org</a><br /> <br /><b>Phone:</b> 0208 428 7287
              </p>
            </div>
          </div>
          <div className="has-text-weight-semibold mb-2 mt-2">Or via the contact form below:</div>
          <ContactForm />
        </div>
      </div>
    </section>
  </Layout>
)
